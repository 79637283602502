export const FUNKTURM_REGULAR = `
  font-family: 'Funkturm';
  font-weight: 400;
`

export const POPPINS_REGULAR = `
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
`

export const POPPINS_MEDIUM = `
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
`

export const POPPINS_BOLD = `
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
`

export const FUTURA_REGULAR = `
  font-family: 'Futura-PT', sans-serif;
  font-weight: 200;
`

export const FUTURA_MEDIUM = `
  font-family: 'Futura-PT', sans-serif;
  font-weight: 500;
`

export const FUTURA_BOLD = `
  font-family: 'Futura-PT', sans-serif;
  font-weight: 700;
`

export const LIBRE_FRANKLIN_MEDIUM = `
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
`

export const BN_CHESTER_REGULAR = `
  font-family: 'BN Chester', sans-serif;
  font-weight: 400;
`

export const AGRANDIR_GRANDLIGHT = `
  font-family: 'Agrandir-GrandLight', sans-serif;
  font-weight: 400;
`

export const AGRANDIR_GRANDHEAVY = `
  font-family: 'Agrandir-GrandHeavy', sans-serif;
  font-weight: 400;
`

export const AGRANDIR_REGULAR = `
  font-family: 'Agrandir-Regular', sans-serif;
  font-weight: 400;
`

export const AGRANDIR_TEXTBOLD = `
  font-family: 'Agrandir-TextBold', sans-serif;
  font-weight: 400;
`

export const MAGILIO = `
  font-family: 'Magilio-Regular', sans-serif;
  font-weight: 400;
`

export const DERIVIA = `
  font-family: 'Derivia-Regular', sans-serif;
  font-weight: 400;
`

export const DM_SERIF_DISPLAY_BOLD = `
  font-family: 'DM Serif Display', sans-serif;
  font-weight: 700;
`

export const DM_SERIF_DISPLAY_REGULAR = `
  font-family: 'DM Serif Display', sans-serif;
  font-weight: 400;
`

export const LORA_REGULAR = `
  font-family: 'Lora', sans-serif;
  font-weight: 400;
`

export const LORA_MEDIUM = `
  font-family: 'Lora', sans-serif;
  font-weight: 500;
`

export const LORA_BOLD = `
  font-family: 'Lora', sans-serif;
  font-weight: 600;
`

export const MARTEL_LIGHT = `
  font-family: 'Martel', sans-serif;
  font-weight: 200;
`

export const COOPER_NOUVEAU = `
  font-family: 'Cooper-Nouveau', sans-serif;
  font-weight: 400;
`

export const PEACHY_KEEN = `
  font-family: 'peachy-keen-jf', sans-serif;
  font-weight: 400;
`

export const QUASIMODA = `
  font-family: 'quasimoda', sans-serif;
  font-weight: 400;
`

export const ORIGIN_SUPER = `
  font-family: 'origin-super-condensed', sans-serif;
  font-weight: 400;
`

export const BOOKMANIA = `
  font-family: 'bookmania', sans-serif;
  font-weight: 400;
`